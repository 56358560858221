<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your
          password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="validateForm">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="postForgotPassword"
          >
            <!-- email -->
            <b-form-group
              label="Email Address"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="forgotPasswordInput"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- <SpinnerBtn v-if="isLoading" /> -->
            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->

      <div v-if="resetLink">
        <br>
        <h1> Link that will be sent via email or phone </h1>
        <a :href="resetLink"> {{ resetLink }} </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      forgotPasswordInput: null,
      user: {
        email: null,
        phone_number: null,
        // email: 'michael@gmail.com',
        // phone_number: '+65 86345511',
      },
      resetLink: null,

      required,
      email,
    }
  },
  methods: {
    postForgotPassword() {
      if (this.forgotPasswordInput.includes('@')) {
        this.user.email = this.forgotPasswordInput
      } else {
        this.user.phone_number = this.forgotPasswordInput
      }

      const formData = new FormData()
      formData.append('user', JSON.stringify(this.user))
      this.$http
        .post(`/api/auth/forgot-password`, formData)
        .then(response => {
          const { success, message, output } = response.data
          if (success) {
            this.$toastSuccess('Operation Success', message)
            this.resetLink = output.confirmation_link
          } else {
            this.$toastDanger()
          }
        })
        .catch(error => {
          this.$handleResponseError(error)
        })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
</style>